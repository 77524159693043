import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Input } from '@mui/material';
import { UploadFile } from '../../services/uploadfileservices/FileUpload';

const FileUploadModal = ({ isOpen, onClose, onFileUpload, GetData, setIsLoadingUsers }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState('');

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if the selected file has a CSV extension
      if (file.name.endsWith('.csv')) {
        setSelectedFile(file);
        setError('');
      } else {
        setSelectedFile(null);
        setError('Only CSV files are allowed.');
      }
    }
  };

  const handleUpload = async () => {

    if (selectedFile) {
      onClose();
      setIsLoadingUsers(true);
      const data = await UploadFile(selectedFile);
      if(data.status){
        GetData();
      }else{
        setIsLoadingUsers(false);
      }
     
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>File Upload</DialogTitle>
      <DialogContent>
        <Input type="file" onChange={handleFileChange} />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpload} disabled={!selectedFile}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadModal;
