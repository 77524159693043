import { createContext, useState } from "react";



export const dataContext = createContext();

export default function DataProivder({children}) {
    const [applicationId,setApplicationId]= useState(0)
  return (
    <dataContext.Provider value={{applicationId,setApplicationId}}>
    {children}
    </dataContext.Provider>
  )
}
