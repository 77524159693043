import axiosInstance from "src/utils/axios";

export const UploadFile = async(data)=>{
    const formdata = new FormData();
    formdata.append('file', data)
    const result= await axiosInstance.post('/admin/import/csv/file', formdata).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};