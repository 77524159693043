import { useState } from 'react';
import Swal from 'sweetalert2';
import { TextField,Button,Box ,Typography,Modal ,Grid,Select ,MenuItem,InputLabel ,FormControl } from '@mui/material';

import '../../assets/css/style.css';
import { useFormik } from 'formik';
import { addUserService } from '../../services/dashboardservices/userManagementService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddUser({open,setOpen,handleRefersData}) {

  const handleClose = () => setOpen(false);
 const formik = useFormik({
   initialValues: {
    first_name:"",
    last_name:"",
    email:"",
    phone:"",
    password:"",
    country:"",
    province:"",
    city:"",
    role_id:""
   },
   onSubmit: async(values,{resetForm}) => {
     const result = await addUserService(values)
     console.log("the result is",result.status)
     if(result.status===true){
       Swal.fire({
         icon:'success',
         title:"Account has been created successfully",
         showConfirmButton: true,
         timer: 15000
       })
       resetForm()
       handleClose()
       handleRefersData()
     }
     else{
      
       Swal.fire({
         icon: 'error',
         title: result.message,
         text:result.errors.email[0],
         showConfirmButton: true,
         timer: 15000
       })
     }
   },
 })
  return (
    <div>
     
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title"  variant="h4" aligin="center" component="h2">
           Add User Account
          </Typography>
          <div>
          <form onSubmit={formik.handleSubmit} className='mt-5'>
          <Grid container spacing={2}>
  <Grid item xs={12} md={4} lg={4}>
  <div className='py-10'>
          <TextField
         type="text"
         label="First Name"
         name='first_name'
         onChange={formik.handleChange}
         value={formik.values.first_name}
         fullWidth
         required
        />
          </div>
  </Grid>
  <Grid item xs={12} md={4} lg={4}>
  <div className='py-10'>
          <TextField
         type="text"
         label="Last Name"
         name='last_name'
         onChange={formik.handleChange}
         value={formik.values.last_name}
         fullWidth
         required
        />
          </div>
  </Grid>
  <Grid item xs={12} md={4} lg={4}>
  <div className='py-10'>
  <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          name="role_id"
          onChange={formik.handleChange}
          value={formik.values.role_id}
          label="Select Role"
          required
       
        >
          <MenuItem value={1}>Admin</MenuItem>
          <MenuItem value={2}>User</MenuItem>
     
        </Select>
      </FormControl>
          </div>
  </Grid>
  <Grid item xs={12}>
  <div className='py-10'>
          <TextField
         type="email"
         label="Email"
         name='email'
         onChange={formik.handleChange}
         value={formik.values.email}
         fullWidth
         required
        />
          </div>
          <div className='py-10'>
          <TextField
         type="text"
         label="Phone"
         name='phone'
         onChange={formik.handleChange}
         value={formik.values.phone}
         fullWidth
         required
        />
        </div>
          <div className='py-10'>
          <TextField
         type="password"
         label="Password"
         name='password'
         onChange={formik.handleChange}
         value={formik.values.password}
         fullWidth
         required
        />
          </div>
  </Grid>

  <Grid item xs={12} md={4} lg={4}>
  <div className='py-10'>
          <TextField
         type="text"
         label="City"
         name='city'
         onChange={formik.handleChange}
         value={formik.values.city}
         fullWidth
         required
        />
          </div>
    </Grid>
  <Grid item xs={12} md={4} lg={4}>
  <div className='py-10'>
          <TextField
         type="text"
         label="Province"
         name='province'
         onChange={formik.handleChange}
         value={formik.values.province}
         fullWidth
         required
        />
          </div>
    </Grid>
  <Grid item xs={12} md={4} lg={4}>
  <div className='py-10'>
          <TextField
         type="text"
         label="Country"
         name='country'
         onChange={formik.handleChange}
         value={formik.values.country}
         fullWidth
         required
        />
          </div>
    </Grid>
    <Grid   
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      classes={"py-10"}
    xs={12} md={12} lg={12}>
    <Button
              variant="contained"
              type="submit"
            
            >
              Add New User
            </Button>
    </Grid>
  </Grid>
          
        
        
      
        
        
  

          </form>




        

          </div>
        </Box>
      </Modal>
    </div>
  );
}
