/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-undef */
import { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2';

// @mui
import { Container, Box, Button, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import Page from '../../components/Page';

import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
    
    deleteBlogService,
    getAllBlogsService,
    
} from '../../services/blogservices/blogService';

import AddBlog from '../../components/models/AddBlog';
import { HOST } from '../../config';
import UpdateBlog from '../../components/models/UpdateBlog';

// ----------------------------------------------------------------------

export default function AllBlogs() {
  const [user] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
//   const [validationErrors, setValidationErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [rowData, setRowData] = useState({});

  const handleOpenModel = () => {
    setOpen(!open);
  };
  const handleOpenModelUpdate = () => {
    setOpenUpdate(!openUpdate);
  };
  
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: '#',
        size: 10,
      },
      {
        accessorKey: 'thumbnail',
        header: 'Thumbnail',
        Cell: ({ row }) => (
          <img
            alt={row.original?.name}
            src={`${HOST}/${row.original.thumbnail}`}
            style={{ width: 80, height: 80, borderRadius: 1.5 }}
          />
        ),
      },
      {
        accessorKey: 'name',
        header: 'Title',
        size: 50,
        enableEditing: true,
      },
      {
        accessorKey: 'category',
        header: 'Category',
        size: 50,
      },
      
    ],
    []
  );

  // const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const getAllBlogsPosts = async () => {
    try {
      const result = await getAllBlogsService();
      console.log("the blog data is",result)
      if (result.data) {
        setTableData(result.data);
      }
    } catch (error) {
      console.log(error, 'accounts');
    }
  };

 



  //  User Delete function
  const openDeleteConfirmModal = async (row) => {
      
      // alert(row.original.id)
      Swal.fire({
          title: 'Do you want to Delete a Blog',
          showCancelButton: true,
          confirmButtonText: 'Delete',
        }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
          setIsLoadingUsers(true);
        const reuslt = await deleteBlogService(row.original.id);
        if (reuslt.status) {
            getAllBlogsPosts();
            Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Blog has been deleted successfully.',
            });
            setIsLoadingUsers(false);
        }
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
        setIsLoadingUsers(false);
      }
    });
  };
  useEffect(() => {
    getAllBlogsPosts();
  }, []);
  const handleSyncData=(status)=>{
    setIsLoadingUsers(status)
    setIsUpdatingUser(status)
    if(status===true){
        getAllBlogsPosts()
    }
  }
  useEffect(() => {
    if (user?.length) {
      setTableData(user);
    }
  }, [user]);

  return (
    <Page title="Blog">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="Blog" links={[{ name: '', href: '' }]} />

        <MaterialReactTable
          columns={columns}
          data={tableData}
          enableRowActions
        
          renderRowActions={({ row }) => (
            <>
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                  <IconButton onClick={() => {
                    handleOpenModelUpdate();
                    setRowData(row.original);
                }}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
             
              </Box>
            </>
          )}
          positionActionsColumn="last"
          getRowId={(row) => row.id}
          renderTopToolbarCustomActions={ () => (
            <Button
              variant="contained"
              onClick={()=>handleOpenModel()}
            >
              Create New Blog
            </Button>
          )}
          state={{
            isLoading: isLoadingUsers,
            isSaving: isUpdatingUser,
          }}
        />
        <AddBlog open={open} setOpen={setOpen} handleSyncData={handleSyncData} />
        <UpdateBlog openUpdate={openUpdate} setOpenUpdate={setOpenUpdate} handleSyncData={handleSyncData} rowData={rowData} />
      </Container>
    </Page>
  );
}
