import axiosInstance from "../../utils/axios"



export const UserChartDetails = async()=>{
    const result= await axiosInstance.get('/admin/dashboard/details').then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};
