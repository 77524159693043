import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { TextField,Button,Box ,Typography,Modal ,Grid  } from '@mui/material';

import '../../assets/css/style.css';
import { useFormik } from 'formik';

import { addBlogService, updateBlogService } from '../../services/blogservices/blogService';
import Editor from '../blogeditor/Editor';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "60%",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function UpdateBlog(props) {
    const {openUpdate,setOpenUpdate,handleSyncData,rowData} = props;

  const handleClose = () => setOpenUpdate(false);
 const formik = useFormik({
   initialValues: {
    id:"",
    name:"",
    description:"",
    category:"",
    thumbnail:{},
   },
   onSubmit: async(values,{resetForm}) => {
   
     const result = await updateBlogService(values)
     handleSyncData(true)
     if(result.status===true){
       Swal.fire({
         icon:'success',
         title:"Blog has been Updated successfully",
         showConfirmButton: true,
         timer: 15000
       })
       resetForm()
       handleClose()
       handleSyncData(false)
     }
     else{
      
       Swal.fire({
         icon: 'error',
         title: result.message,
         text:result.errors.email[0],
         showConfirmButton: true,
         timer: 15000
       })
       handleSyncData(false)
     }
   },
 })

 const handleThumbanil=(e)=>{
  
   formik.setFieldValue('thumbnail',e.target.files[0])
 }
useEffect(() => {
  formik.setValues({
    id: rowData.id,
    name: rowData.name,
    description: rowData.description,
    category: rowData.category,
  });
}, [rowData]);
  return (
    <div>
     
      <Modal
        open={openUpdate}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title"  variant="h4" aligin="center" component="h2">
           Add User Account
          </Typography>
          <div>
          <form onSubmit={formik.handleSubmit} className='mt-5'>
          <Grid container spacing={2}>
  <Grid item xs={12} md={6} lg={6}>
  <div className='py-10'>
          <TextField
         type="text"
         label="Title"
         name='name'
         onChange={formik.handleChange}
         value={formik.values.name}
         fullWidth
         required
        />
          </div>
  </Grid>
  <Grid item xs={12} md={6} lg={6}>
  <div className='py-10'>
          <TextField
         type="text"
         label="Category"
         name='category'
         onChange={formik.handleChange}
         value={formik.values.category}
         fullWidth
         required
        />
          </div>
  </Grid>
  <Grid item xs={12}>
  <div className='py-10'>
          <TextField
         type="file"
         label="Thumnail"
         name='thumbnail'
         onChange={handleThumbanil}
        //  value={formik.values.thumbnail}
         fullWidth
         
        />
          </div>
   
  </Grid>

  <Grid item xs={12} md={12} lg={12}>
  <div className='py-10'>
          {/* <TextField
         type="text"
         label="Description"
         name='description'
         onChange={formik.handleChange}
         value={formik.values.description}
         multiline
         rows={10}
         maxRows={20}
         fullWidth
         required
        /> */}
       
       <Editor placeholder="content" formik={formik} />
          </div>
    </Grid>

    <Grid   
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      classes={"py-10"}
    xs={12} md={12} lg={12}>
    <Button
              variant="contained"
              type="submit"
            
            >
              Update Blog
            </Button>
    </Grid>
  </Grid>
          
        
        
      
        
        
  

          </form>




        

          </div>
        </Box>
      </Modal>
    </div>
  );
}
