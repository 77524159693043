/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-undef */
import { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Swal from 'sweetalert2';
import { mkConfig, generateCsv, download } from 'export-to-csv'; 
// @mui
import { Container, Box, Button, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Switch, Space } from 'antd';
import Page from '../../components/Page';

import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {
  changeStatusService,
  deleteRowCheckService,
  deleteUserService,
  getAllUsers,
  updateUserService,
} from '../../services/dashboardservices/userManagementService';
import AddUser from '../../components/models/AddUser';
import { Delete } from '@mui/icons-material';

// ----------------------------------------------------------------------

export default function AllUsers() {
  const [user] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [idCheck, setIdCheck] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [open, setOpen] = useState(false);

  const handleOpenModel = () => {
    setOpen(!open);
    console.log(idCheck, 'idCheck12')
  };

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });

  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: '#',
        size: 10,
      },
      {
        accessorKey: 'first_name',
        header: 'First Name',
        size: 50,
        enableEditing: true,
      },
      {
        accessorKey: 'last_name',
        header: 'Last Name',
        size: 50,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 50,
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        size: 50,
      },
      {
        accessorKey: 'city',
        header: 'City',
        size: 50,
      },

      {
        accessorKey: 'province',
        header: 'Province',
        size: 150,
      },
      {
        accessorKey: 'country',
        header: 'Country',
        size: 150,
      },
      {
        accessorKey:"role_id",
        header: 'Role',
        size: 150,
        editVariant: 'select',
        Cell:({renderedCellValue})=>(
          <span>{renderedCellValue==1 ? "Admin" : "User"}</span>
        ),
        editSelectOptions:  [
        {
          label: 'Administrator',
          value: '1',
        },
        {
          label: 'User',
          value: '2',
        },
      ]
      }
    ],
    []
  );

  // const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);

  const getAlluser = async () => {
    try {
      setIsLoadingUsers(true);
      const result = await getAllUsers();

      if (result.data) {
        setTableData(result.data);
        setIsLoadingUsers(false);
      }
    } catch (error) {
      console.log(error, 'accounts');
      setIsLoadingUsers(false);
    }
  };

  const DeleteRowCollection = async (data) => {
    try {
      const result = await deleteRowCheckService(data);
      console.log(result, 'resultCheck')
      // if (result.data) {
      //   setTableData(result.data);
      // }
    } catch (error) {
      console.log(error, 'accounts');
    }
  };

  const changeStatus = async (id, status) => {
    const changeStatus = status === 'active' ? 'inactive' : 'active';
    const result = await changeStatusService(id, changeStatus);

    if (result.status === true) {
      getAlluser();
    }
  };
  const handleCreateUser = (e) => {
    console.log('handle create usrer', e);
  };

  //  updating user
  const handleSaveUser = async ({ values, table }) => {
    setIsUpdatingUser(true);
    setIsLoadingUsers(true);
    const result = await updateUserService(values);

    console.log('handle save user ', result);
    if (result.status === true) {
      setIsUpdatingUser(false);
      setIsLoadingUsers(false);
      setValidationErrors({});
      table.setEditingRow(null);
    }
  };

  //  User Delete function
  const openDeleteConfirmModal = async (row) => {
    // alert(row.original.id)
    Swal.fire({
      title: 'Do you want to Delete a user',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const reuslt = await deleteUserService(row.original.id);
        if (reuslt.status) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted!',
            text: 'User has been deleted successfully.',
          });
          getAlluser();
        }
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

 const handleRefersData=()=>{
  getAlluser();
  alert("hello")
 }
  useEffect(() => {
    getAlluser();
  }, []);

  useEffect(() => {
    if (user?.length) {
      setTableData(user);
    }
  }, [user]);

  return (
    <Page title="Users">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs heading="User" links={[{ name: '', href: '' }]} />

        <MaterialReactTable
          columns={columns}
          data={tableData}
          enableRowActions
          createDisplayMode="row"
          editDisplayMode="row"
          enableEditing
          onCreatingRowCancel={() => setValidationErrors({})}
          onCreatingRowSave={handleCreateUser}
          onEditingRowCancel={() => setValidationErrors({})}
          onEditingRowSave={handleSaveUser}
          renderRowActions={({ row, table }) => (
            <>
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                  <IconButton onClick={() => table.setEditingRow(row)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Space direction="vertical">
                  <Switch
                    className="font-medium font-[Inter] text-[13px]"
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={() => changeStatus(row.original.id, row.original.status)}
                    checked={row.original.status === 'active'}
                    style={{
                      background: row.original.status === 'active' ? 'green' : 'red',
                    }}
                  />
                </Space>
              </Box>
            </>
          )}
          getRowId={(row) => row.id}
          renderTopToolbarCustomActions={({row, table}) => {
          
            
            return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>

              <Button variant="contained" onClick={() => handleOpenModel()}>
                Create New User
              </Button>
              <Button
                  disabled={table.getPrePaginationRowModel().rows.length === 0}
                  //export all rows, including from the next page, (still respects filtering and sorting)
                  onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                  startIcon={<FileDownloadIcon />}
                >
                  Export CSV
                </Button>
            </Box>
            )
          }}
          positionActionsColumn="last"
          state={{
            isLoading: isLoadingUsers,
            isSaving: isUpdatingUser,
          }}
        />
        <AddUser open={open} setOpen={setOpen} handleRefersData={handleRefersData} />
      </Container>
    </Page>
  );
}
