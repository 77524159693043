
import { Grid, Container,} from '@mui/material';
// import { useTheme } from '@mui/material/styles';

import useSettings from '../../hooks/useSettings';

import Page from '../../components/Page';


import ApplicationFilesTable from '../../sections/tables/ApplicationFilesTable';


export default function ApplicationFiles() {
  const { themeStretch } = useSettings();
//   const theme = useTheme();





  return (
    <Page title="Dashboard Uploaded Files">
      <Container maxWidth={themeStretch ? false : 'xl'}>
    
         
       
        <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <ApplicationFilesTable />
          </Grid>
          </Grid>
      </Container>
    </Page>
  );
}
