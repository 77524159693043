import axiosInstance from "../../utils/axios"


export const getAllApplicationsService = async()=>{
    const result= await axiosInstance.get('/admin/get/app/records').then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};
export const addApplicationsService = async(data)=>{
    const result= await axiosInstance.post('/admin/add/app/record',data).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};


export const updateApplicationsService = async(data)=>{
    const result= await axiosInstance.post('/admin/update/app/record',data).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};
export const deleteApplicationService = async(id)=>{
    const result= await axiosInstance.get(`/admin/del/app/record/${id}`).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};
export const hiddenApplicationService = async(data)=>{
    const result= await axiosInstance.post(`/admin/hide/emp/record`,data).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};

export const getAllHiddenApplicationsService = async()=>{
    const result= await axiosInstance.get('admin/get/all/hided/employer').then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};

export const getAllApplicationFilesService = async()=>{
    const result= await axiosInstance.get('/admin/get/cdv/collection').then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};

export const getAllApplicationByFilesService = async(id)=>{
    const result= await axiosInstance.get(`/admin/get/file/data/by/${id}`).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};

export const deleteApplicationFileService = async(id)=>{
    const result= await axiosInstance.delete(`/admin/del/single/csv/file/${id}`).then((res)=>{
        return res.data
        }).catch((err)=>{
            return err.response.data;
        });  
        return result;
};